export const prices = [
    {
        id: 0,
        name: 'Any'
    },
    {
        id: 1,
        name: '1 - 19'
    },
    {
        id: 2,
        name: '20 - 39'
    },
    {
        id: 3,
        name: '40 - 59'
    },
    {
        id: 4,
        name: '60 - 79'
    },
    {
        id: 5,
        name: 'More than 80'
    }
]